import React, { useEffect } from 'react';
import './App.css';
import hive from "./hive"
import LeftMenu from "./hive/leftMenu"
import Header from "./hive/header"
import ToolTip from "./hive/tooltip"
import MobileFLoorMenu from "./hive/mobileFloorMenu"
import { useState } from "@hookstate/core"
import { R_HEADER_TOP_HEIGHT, R_HEADER_HEIGHT, R_MOBILE_HEADER_HEIGHT } from './hive/constant';
import GoBack from './hive/GoBack';
import Image from './hive/Image';
import Button from './hive/Button';
import BotMenu from './hive/BotMenu';
import MobileLogo from './hive/MobileLogo';
import Tour from './hive/Tour';
import InfoCard from './hive/InfoCard';
import Compas from './hive/Compas';
import SearchButton from './hive/SearchButton';
import Loading from './hive/Loading';
import { Fullscreen, ThreeDRotation } from '@material-ui/icons';
import Search from './hive/Search';
import Favorites from './hive/Favorites';
import RotateIcon from './hive/RotateIcon';

function FullScreenButton() {
  const { height, mainColor, totalWidth, isMobile } = useState(hive.state)

  const size = height.get() * (isMobile.get() ? 0.15 : 0.07);

  return <div
    onClick={() => {
      hive.toggleFullscreen()
    }}
    style={{
      position: "absolute",
      height: size,
      width: size,
      marginTop: height.get() - size * (isMobile.get() ? 2.5 : 1.1),
      marginLeft: totalWidth.get() - size * 1.2,
      zIndex: 3,
      color: `#${mainColor.get()}`,
      cursor: "pointer",
    }}>
    <Fullscreen style={{
      width: size + "px",
      height: size + "px",
    }}></Fullscreen>
  </div>
}

function TypeSelector() {
  const { types, height, width, typeColors } = useState(hive.state)

  return <div style={{
    position: "absolute",
    display: "flex",
    zIndex: 1,
    marginLeft: width.get() * 0.05,
    marginTop: height.get() * 0.02,
  }}>
    {types.map((type, i) =>
      <div key={i} style={{
        width: height.get() * 0.06,
        height: height.get() * 0.06,
        lineHeight: height.get() * 0.06 + "px",
        borderRadius: height.get() * 0.06 * 0.5,
        border: "1px solid #333333",
        backgroundColor: `rgb(${typeColors.get()[i][0]}, ${typeColors.get()[i][1]}, ${typeColors.get()[i][2]}, 255)`,
        textAlign: "center",
        fontSize: height.get() * 0.021 + "px",
        color: "white",
        marginRight: height.get() * 0.01,
        opacity: type.get() ? "0.8" : "0.2",
        transition: "opacity 0.2s",
        userSelect: "none",
        cursor: "pointer",
      }} onClick={() => {
        hive.toggleType(i)
      }}>
        T{i + 1}
      </div>
    )}
  </div>
}

function App() {
  const { isMobile, image, botMenu, back, tour, infoCard, compas, leftMenu, searchButton, search, favorites, favoriteList, nbrLots } = useState(hive.state)

  useEffect(() => {
    hive.vision.setContainer(document.querySelector("#visionContainer") as HTMLElement)
    hive.init()
    return () => { }
  }, [])

  return (
    <div className="App">
      <LeftMenu></LeftMenu>
      <TypeSelector></TypeSelector>
      {/* {!isMobile.get() && <Header></Header>} */}
      {/* {(isMobile.get() && !back.get()) && <MobileLogo></MobileLogo>} */}
      {/* {back.get() && <GoBack></GoBack>} */}
      {/* {image.get() && <Image></Image>} */}
      {/* {botMenu.get() && <BotMenu></BotMenu>} */}
      {/* {infoCard.get() && <InfoCard></InfoCard>} */}
      {/* {tour.get() && <Tour></Tour>} */}
      {/* {isMobile.get() && leftMenu.get().length > 0 && <>
        <Button
          onClick={() => { hive.toggleFloorMenu() }}
          topRatio={0.16}
          icon={"layer"}
          text={'Etages'}
        >
        </Button>

        {nbrLots.get() > 0 && <>
          <Button
            onClick={() => { hive.action("setScene", "search") }}
            topRatio={0.32}
            icon={"search"}
            text={'Filtrer'}
          >
          </Button>
          {favoriteList.length > 0 &&
            <Button
              onClick={() => { hive.action("setScene", "favorites") }}
              topRatio={0.48}
              icon={"favorite"}
              text={'Favoris'}
            >
            </Button>
          }
        </>}

      </>
      } */}

      {/* {<FullScreenButton></FullScreenButton>} */}
      {compas.get() && <Compas></Compas>}
      {(nbrLots.get() > 0 && searchButton.get() && !isMobile.get()) && <SearchButton></SearchButton>}
      {search.get() && <Search></Search>}
      {favorites.get() && <Favorites></Favorites>}

      <RotateIcon></RotateIcon>
      <Loading></Loading>
      <div id="visionContainer" style={{
        width: "100vw",
        height: isMobile.get() ? "100vh" : `calc(100vh)`
      }}>
      </div>
      {isMobile.get() && leftMenu.get().length > 0 && <MobileFLoorMenu></MobileFLoorMenu>}
      <ToolTip></ToolTip>
    </div>
  );
}

export default App;
