import React, { useEffect } from 'react';
import { useState } from "@hookstate/core"
import hive from "./index"
import { Print, GetApp, FavoriteBorder } from '@material-ui/icons';
import { config } from 'rxjs';

function numberWithSpaces(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function ToolTip() {
  const { totalWidth, width, height, mainColor, typeColors, isTouch } = useState(hive.state)
  const { poi, lot } = useState(hive.overState)

  const a_width = height.get() * 0.30
  const a_height = height.get() * 0.30
  const color = lot.get() ? typeColors.get()[lot.get().type - 1] : [255, 255, 255]

  useEffect(() => {
    const el = document.querySelector("#tooltip") as HTMLElement

    hive.poiOverevent.subscribe(e => {
      const a_height = height.get() * 0.30
      const a_width = height.get() * 0.30
      const isOnBottom = e.clientY > height.get() * 0.3
      el.style.top = `${e.clientY - (isOnBottom ? a_height + a_height * 0.05 : a_height * -0.10)}px`
      el.style.left = `${e.clientX - a_width / 2}px`
    })
  }, [])

  const iconSize = {
    width: a_height * 0.7 * 0.25 * 0.7,
    height: a_height * 0.7 * 0.25 * 0.7
  }

  return <>
    <div id="tooltip" style={{
      position: "absolute",
      zIndex: 1,
      opacity: poi.get() ? "1" : "0",
      transition: "opacity 0.1s",
      width: a_width,
      height: a_height,
      background: "white",
      borderRadius: a_width * 0.02,
      pointerEvents: "none",
      backgroundImage: lot.get() ? `url(/pois/${lot.get().id}/view.jpg)` : "none",
      backgroundSize: "contain",
      backgroundPosition: "center",
    }}>
    </div>
  </>
}

export default ToolTip