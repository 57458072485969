import React, { useEffect } from 'react';
import { useState } from "@hookstate/core"
import hive from "./index"
import { Flight, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import "./leftMenu.css"

const MAX_DISPLAY_ITEM = 7

function Icon(props: { icon: string, height: number }) {
  const style = {
    width: props.height / 50 + "px",
    display: "block",
    margin: "auto",
    marginTop: -props.height / 60,
  }

  return (
    <div style={{ position: "absolute", width: "75%" }}>
      {props.icon === "Flight" &&
        <Flight style={style}>
        </Flight>
      }
    </div>
  )
}

function LeftMenu() {
  const { width, height, leftMenu, activeScene, mainColor, activeOrbit } = useState(hive.state)

  return (
    <div style={{
      position: "absolute",
      zIndex: 1,
      marginLeft: width.get() * 0.03,
      marginTop: height.get() * 0.3,
    }}>
      {leftMenu.map((item, i) => {
        return <div key={i} style={{
          width: height.get() * 0.07,
          height: height.get() * 0.07,
          lineHeight: height.get() * 0.07 + "px",
          borderRadius: height.get() * 0.07 * 0.5,
          border: "1px solid #333333",
          background: (item.target.get() === activeScene.get() || item.target.get() === activeOrbit.get()) ? `#${mainColor.get()}` : "white",
          cursor: (item.target.get() === activeScene.get() || item.target.get() === activeOrbit.get()) ? "initial" : "pointer",
          marginTop: height.get() * 0.01,
          textAlign: "center",
          fontSize: height.get() * 0.025 + "px",
          color: (item.target.get() === activeScene.get() || item.target.get() === activeOrbit.get()) ? "white" : "#333333",
          userSelect: "none",
        }}
          onClick={() => {
            hive.action(item.action.get(), item.target.get())
          }}
        >
          {item.text.get()}
        </div>
      })}
    </div>
  );
}


export default LeftMenu;
